import React from 'react';
import { IHeaderContainerProps } from '../../../HeaderContainer.types';
import ShadowBottomScreen from '../../../../ScreenWidthContainer/viewer/skinComps/ShadowBottomScreen/ShadowBottomScreen';
import HeaderContainer from '../../HeaderContainer';

const ShadowBottomScreenHeader: React.FC<Omit<IHeaderContainerProps, 'skin'>> =
  props => (
    <HeaderContainer {...props} skin={ShadowBottomScreen}></HeaderContainer>
  );

export default ShadowBottomScreenHeader;
