import * as React from 'react';
import WrapperElement from '../../WrapperElement';
import { SkinScreenWidthContainerProps } from '../../../SkinScreenWidthContainer';
import skinStyles from './styles/skins.scss';

const ShadowBottomScreen: React.FC<SkinScreenWidthContainerProps> = ({
  wrapperProps,
  children,
}) => {
  return (
    <WrapperElement
      {...wrapperProps}
      skinClassName={skinStyles.ShadowBottomScreen}
      skinStyles={skinStyles}
    >
      <div className={skinStyles.centerArea}>
        <div className={`${skinStyles.ln} ${skinStyles.left}`}></div>
        <div className={`${skinStyles.ln} ${skinStyles.center}`}></div>
        <div className={`${skinStyles.ln} ${skinStyles.right}`}></div>
      </div>
      <div className={skinStyles.screenWidthBackground}>
        <div className={skinStyles.bg}></div>
      </div>
      <div className={skinStyles.centeredContent}>
        <div className={skinStyles.centeredContentBg}></div>
        <div className={skinStyles.inlineContent}>{children}</div>
      </div>
    </WrapperElement>
  );
};

export default ShadowBottomScreen;
