import HeaderContainer_ShadowBottomScreenComponent from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/skinComps/ShadowBottomScreen/ShadowBottomScreen.skin';


const HeaderContainer_ShadowBottomScreen = {
  component: HeaderContainer_ShadowBottomScreenComponent
};


export const components = {
  ['HeaderContainer_ShadowBottomScreen']: HeaderContainer_ShadowBottomScreen
};


// temporary export
export const version = "1.0.0"
